import React from 'react'
import { getProperty } from 'dot-prop'

type Props = {
  orderDetails: any
}

export const ambulanceMapper: any = {
  alsElite: 'ALS Elite',
  alsStandard: 'ALS Standard',
  alsSupreme: 'ALS Supreme',
  blsElite: 'BLS Elite',
  blsStandard: 'BLS Standard',
  blsSupreme: 'BLS Supreme',
  als: 'ALS',
  bls: 'BLS',
  BLS: 'BLS',
  ALS: 'ALS',
  default: '-',
  ALS_STANDARD: 'ALS Standard',
  ALS_ELITE: 'ALS Elite',
  ALS_SUPREME: 'ALS Supreme',
  BLS_STANDARD: 'BLS Standard',
  BLS_ELITE: 'BLS Elite',
  BLS_SUPREME: 'BLS Supreme',
}

export default function TrackingCaseDetails({ orderDetails }: Props) {
  const licensePlate = getProperty(
    orderDetails,
    'assignmentDetails.fleetDetails.ambNumberPlate',
    '',
  )
  const category = getProperty(orderDetails, '', '-')
  const type = getProperty(orderDetails, 'fleetTypeRequested', '')
  const driverName = getProperty(orderDetails, 'assignmentDetails.pilotDetails.meta.userName', '')
  const mobileNumber = getProperty(orderDetails, 'assignmentDetails.pilotDetails.meta.mobile', '')
  const emtName = getProperty(orderDetails, 'erAssignmentDetails.emt.name') ||  getProperty(orderDetails, 'assignmentDetails.paramedicDetails.meta.userName', '')
  const emtMobileNumber = getProperty(orderDetails, 'erAssignmentDetails.emt.phoneNo') || getProperty(
    orderDetails,
    'assignmentDetails.paramedicDetails.meta.mobile',
    '-',
  )
  const pickup = getProperty(orderDetails, 'waypoints[0].address.location', '')
  const drop = getProperty(orderDetails, 'waypoints[1].address.location', '')

  console.log(orderDetails)

  console.log(pickup)
  return (
    <div className="p-4 gap-y-6 flex flex-col">
      <div className="grid grid-cols-3 ">
        <CaseItem title="license Plate" value={licensePlate} />
        <CaseItem title="Category" value={category} className="text-center" />
        <CaseItem title="Type" value={type ? ambulanceMapper[type] : '-'} className="text-right" />
      </div>
      <div className="grid grid-cols-3 ">
        <CaseItem title="Driver Name" value={driverName} />
        <CaseItem title="" value={''} className="block" />
        <CaseItem title="Mobile Number" value={`+91 ${mobileNumber}`} className="text-right" />
      </div>
      <div className="grid grid-cols-3 ">
        <CaseItem title="EMT Name" value={emtName} />
        <CaseItem title="" value={''} className="block" />
        <CaseItem title="Mobile Number" value={`+91 ${emtMobileNumber}`} className="text-right" />
      </div>
      <div className="grid grid-cols-3 ">
        <CaseItem title="Pickup" value={pickup} />
        <CaseItem title="" value={''} className="block" />
        <CaseItem title="Drop" value={drop} className="text-right" />
      </div>
    </div>
  )
}

const CaseItem = ({
  title,
  value,
  className,
}: {
  title: string
  value: string | undefined
  className?: string | undefined
}) => {
  return (
    <div className={className}>
      <p className="text-sm font-normal capitalize text-slate-500">{title}</p>
      <p className="text-sm font-semibold text-slate-900">{value || (title && '-')}</p>
    </div>
  )
}
